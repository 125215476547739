import {def} from "@/data/dictionnaire";
import {cssVal, getDataByCodePostal, newInput, texteForSiege, tryToDate} from "@/data/helpers";


export const changementRegimeMatrimonial = {
    title: "Changement de Régime Matrimonial",
    texte: "Aux termes d'un acte en date du {{acteGroup.dateActe}} reçu par Me {{acteGroup.recuPar}}, Notaire à {{acteGroup.villeStr}}, <strong>{{demandeurGroup_1.civilite}} {{demandeurGroup_1.prenom}} {{demandeurGroup_1.nom}}</strong> {{né_1}} le {{demandeurGroup_1.dateNaissance}}, à {{demandeurGroup_1.lieuNaissance}} ({{demandeurGroup_1.lieuNaissanceCp}}) et <strong>{{demandeurGroup_2.civilite}} {{demandeurGroup_2.prenom}} {{demandeurGroup_2.nom}}</strong> {{né_2}} le {{demandeurGroup_2.dateNaissance}}, à {{demandeurGroup_2.lieuNaissance}} ({{demandeurGroup_2.lieuNaissanceCp}}) domiciliés {{lieuResidenceGroup}}, mariés sous le régime de la {{acteGroup.acteRegimeActuel}} ont décidé d'adopter pour l'avenir le régime de la {{acteGroup.acteNouveauRegime}}. Oppositions : {{oppositionGroup.opposition}}",
    script: (text, varText, attribute) => {
        let tmpText = "";
        switch (attribute) {
            case "demandeurGroup_1.civilite":
                if(varText['demandeurGroup_1.civilite'] === 'homme') {
                    tmpText = cssVal('M.');
                } else if(varText['demandeurGroup_1.civilite'] === 'femme'){
                    tmpText = cssVal('Mme');
                }
                break;
            case "demandeurGroup_2.civilite":
                if(varText['demandeurGroup_2.civilite'] === 'homme') {
                    tmpText = cssVal('M.');
                } else if(varText['demandeurGroup_2.civilite'] === 'femme'){
                    tmpText = cssVal('Mme');
                }
                break;
            case "né_1":
                if(varText['demandeurGroup_1.civilite'] === 'femme') {
                    tmpText = 'née';
                } else {
                    tmpText = 'né';
                }
                break;
            case "né_2":
                if(varText['demandeurGroup_2.civilite'] === 'femme') {
                    tmpText = 'née';
                } else {
                    tmpText = 'né';
                }
                break;
            case "lieuResidenceGroup":
                tmpText = texteForSiege(varText, 'lieuResidenceGroup');
                break;
            case "acteGroup.dateActe":
            case "demandeurGroup_1.dateNaissance":
            case "demandeurGroup_2.dateNaissance":
                tmpText = cssVal(tryToDate(varText[attribute]));
                break;
            default:
                return undefined;
        }
        return tmpText;
    },
    model: {
        lieuResidenceGroup: [{}]
    },
    schema: [
        {
            //0
            name: "acteGroup",
            label: "Demandeur",
            type: "group",
            value: [{}],
            children: [
                newInput(def.dateActe, '_required'),
                newInput(def.recuPar, '_required'),
                newInput(def.villeStr, '_required'),
                newInput(def.acteRegimeActuel, '_required'),
                newInput(def.acteNouveauRegime, '_required'),
            ]
        },
        {
            //1
            name: "demandeurGroup_1",
            label: "Demandeur Conjoint 1",
            type: "group",
            value: [{}],
            children: [
                newInput(def.civilite, '_required'),
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.dateNaissance, '_required'),
                newInput(def.lieuNaissanceCp, '_required'),
                newInput(def.lieuNaissance, '_required'),
            ]
        },
        {
            //2
            name: "demandeurGroup_2",
            label: "Demandeur Conjoint 2",
            type: "group",
            value: [{}],
            children: [
                newInput(def.civilite, '_required'),
                newInput(def.nom, '_required'),
                newInput(def.prenom, '_required'),
                newInput(def.dateNaissance, '_required'),
                newInput(def.lieuNaissanceCp, '_required'),
                newInput(def.lieuNaissance, '_required'),
            ]
        },
        {
            //3
            name: "lieuResidenceGroup",
            label: "Lieu de Résidence",
            type: "group",
            value: [{}],
            children: [
                newInput(def.adresse, '_required'),
                newInput(def.adresseSuite),
                newInput(def.codePostal, '_required', {
                    on: {
                        change: async (val) => {
                            val = val.target.value;
                            if (val.length !== 5) {
                                return false;
                            }

                            const data = await getDataByCodePostal(val);

                            if (Object.keys(data).length === 0) {
                                return false;
                            }

                            changementRegimeMatrimonial.schema[3].children[3].options = data.options;
                            changementRegimeMatrimonial.model.lieuResidenceGroup[0].ville = data.ville_slug;
                        }
                    }
                }),
                newInput(def.ville, '_validUniqueSelect'),
            ]
        },
        {
            //4
            name: "oppositionGroup",
            label: "Oppositions",
            type: "group",
            value: [{}],
            children: [
                newInput(def.opposition, '_required')
            ]
        }
    ]
}